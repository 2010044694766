@use 'sass:math';

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  @extend .flag-icon-background;
  position: relative;
  display: inline-block;
  width: math.div(4, 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }

  &.flag-icon-1xh {
      width: math.div(4, 3) * 1.5em;
      height: 1.5em;
      line-height: 1.5em;
  }

  @for $i from 2 through 5 {
    &.flag-icon-#{$i}x {
      width: math.div(4, 3) * $i * 1em;
      height: $i * 1em;
      line-height: $i * 1em;
    }
    &.flag-icon-#{$i}xh {
      width: math.div(4, 3) * ($i + 0.5) * 1em;
      height: ($i + 0.5) * 1em;
      line-height: ($i + 0.5) * 1em;
    }
  }
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg);
    &.flag-icon-squared {
      background-image: url(#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg);
    }
  }
}
